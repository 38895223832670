import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from 'components/ui/dialog';
import {
  CloseButton,
  InvoiceContent,
  InvoiceHeader,
  InvoiceItem as Item,
  InvoiceItemContent,
  InvoiceItems,
  SummaryTitle,
  InvoiceItemDivider,
  TotalLabel,
} from './style';

import useTheme from 'hooks/useTheme';
import { Invoice } from 'app/graphql/generated/admin/graphql';
import { DeepPartial } from '@rye-com/rye-data-layer';
import { InvoiceItem } from '../BillingInvoiceTabContent/column';
import { CardDescription } from 'components/ui/card';
type Props = {
  open: boolean;
  invoice?: DeepPartial<Invoice>;
  rowData?: InvoiceItem;
  onOpenChange: (open: boolean) => void;
};

export const InvoiceDialog = ({ open, invoice, onOpenChange, rowData }: Props) => {
  const { theme } = useTheme();
  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent style={{ borderColor: '#333' }}>
        <DialogHeader>
          <DialogTitle>
            Invoice #{rowData?.id} • {rowData?.billingPeriod}{' '}
          </DialogTitle>
          <DialogDescription>
            <InvoiceContent className="rounded-lg border">
              <InvoiceHeader>
                <SummaryTitle thememode={theme}>Balance Summary</SummaryTitle>
                <CardDescription>
                  The invoice total is a breakdown of order purchases, commission payouts, and fees.
                </CardDescription>
              </InvoiceHeader>
              <InvoiceItems>
                {invoice?.items?.map((item) => (
                  <Item>
                    <InvoiceItemContent>
                      <div>{item.description}</div>
                      <InvoiceItemDivider />
                      <div>
                        {new Intl.NumberFormat('en-US', {
                          style: 'currency',
                          currency: item.amount?.currency,
                        }).format(Number(item.amount?.value) / 100)}
                      </div>
                    </InvoiceItemContent>
                  </Item>
                ))}
                <Item className="mt-[100px]">
                  <InvoiceItemContent>
                    <TotalLabel thememode={theme}>Total</TotalLabel>
                    <InvoiceItemDivider thememode={theme} />
                    <TotalLabel thememode={theme}>
                      {new Intl.NumberFormat('en-US', {
                        style: 'currency',
                        currency: invoice?.amount?.currency ?? 'USD',
                      }).format(Number(invoice?.amount?.value) / 100)}
                    </TotalLabel>
                  </InvoiceItemContent>
                </Item>
              </InvoiceItems>
            </InvoiceContent>
          </DialogDescription>
        </DialogHeader>
        <DialogFooter>
          <CloseButton thememode={theme} onClick={() => onOpenChange(false)}>
            Close
          </CloseButton>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
